@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  .node-get-started-page .field-name-body {
    position: relative;
  }

  /*
  * Prefixed by https://autoprefixer.github.io
  * PostCSS: v7.0.29,
  * Autoprefixer: v9.7.6
  * Browsers: last 7 version
  */

  .node-forum-form > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .node-forum-form > div .form-item-title {

  }

  .node-forum-form > div .field-name-taxonomy-forums {

  }

  .node-forum-form > div .field-name-body {

  }

  .node-forum-form > div .field-name-field-content-tags {

  }

  .node-forum-form > div .field-name-field-content-tags .form-checkboxes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }

  .node-forum-form > div .form-actions {

  }

  /*
  * Prefixed by https://autoprefixer.github.io
  * PostCSS: v7.0.29,
  * Autoprefixer: v9.7.6
  * Browsers: last 7 version
  */

  #block-views-status-blade-block .field-name-field-status-items > .field-items {
    display: -ms-grid;
    display: grid;
  }

  #block-views-status-blade-block .field-name-field-status-items > .field-items > .field-item {
    margin: 0 20px;
    width: 368px;
  }

  #block-views-status-blade-block .field-name-field-status-items > .field-items > .field-item:first-child {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1 / span 2;
  }

  #block-views-status-blade-block .field-name-field-status-items > .field-items > .field-item:nth-child(2) {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1;
  }

  #block-views-status-blade-block .field-name-field-status-items > .field-items > .field-item:nth-child(3) {
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-row: 1;
    grid-row: 1;
  }

  #block-views-status-blade-block .field-name-field-status-items > .field-items > .field-item:last-child {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 2;
    grid-row: 2;
  }






  /* .ie10up{} */
}
